import React from 'react';
import PropTypes from 'prop-types';

import { Box, Image, Flex } from '../../atoms';

import clutch from './awards/clutch.png';
import cssDesAwards from './awards/css-des-awards.svg';
import cssDesAwardsWhite from './awards/css-des-awards_w.svg';
import awards from './awards/awards.svg';
import awardsWhite from './awards/awards_w.svg';
import beh from './awards/beh.svg';
import behWhite from './awards/beh_w.svg';

const Achievements = ({ color }) => (
  <Flex alignItems="center">
    <Box maxWidth={95} mr={50}>
      <Image src={clutch} />
    </Box>
    <Box mr={50}>
      <Image src={color === 'white' ? cssDesAwardsWhite : cssDesAwards} />
    </Box>
    <Box mr={50}>
      <Image src={color === 'white' ? awardsWhite : awards} />
    </Box>
    <Box>
      <Image src={color === 'white' ? behWhite : beh} />
    </Box>
  </Flex>
);

Achievements.propTypes = {
  color: PropTypes.string,
};

Achievements.defaultProps = {
  color: 'black',
};

export default Achievements;
