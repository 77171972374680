import React from 'react';
import f from 'prop-types';

import { styledSpaces } from '../../../utils';
import { Title, Text, Box, Container as Grid, Col, Row, Image } from '../../atoms';

import logoDataform from './companies/dataform.svg';
import logoFiniata from './companies/finiata.svg';
import logoBibliU from './companies/bibliu.svg';
import logoRateSetter from './companies/ratesetter.svg';
import logoAdversus from './companies/adversus.svg';
import logoCC from './companies/cc.svg';
import logoIndustry from './companies/industry.svg';
import logoPadsplit from './companies/padsplit.svg';
import logoCombinator from './companies/combinator.svg';
import logoNorwest from './companies/norwest.svg';
import logoTribe from './companies/tribe.png';
import logoCIN from './companies/core-innovation-capital.png';
import logoCapital from './companies/capital.svg';
import logoEmergeEducation from './companies/emerge-ed.png';

const TrustedBy = ({ isCase, ...props }) => {
  const typoSpace = isCase ? 112 : ['l', 'xxl', 100, 112];
  const typoSpace2 = isCase ? 92 : ['l', 'xxl', null, 92];

  const space1 = isCase ? 70 : ['m', 'xxl', null, 70];
  const space2 = isCase ? 60 : ['m', 'xxl', null, 60];

  const colWidth = isCase ? 'contains' : [1 / 2, null, 'contains'];

  return (
    <Box {...styledSpaces(props)}>
      <Grid textAlign="center">
        <Row>
          <Col width={1}>
            <Title size="h2" fontSize={[47, 64]} maxWidth={800} mx="auto" mb={typoSpace}>
              Trusted by startups with $200M+ in funding:
            </Title>
          </Col>
        </Row>
        <Row
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          gap={isCase ? 85 : ['l', null, 85]}
          mb={20}
        >
          <Col width={colWidth} mb={space1}>
            <Image src={logoDataform} />
          </Col>
          <Col width={colWidth} mb={space1}>
            <Image src={logoFiniata} />
          </Col>
          <Col width={colWidth} mb={space1}>
            <Image src={logoBibliU} />
          </Col>
          <Col width={colWidth} mb={space1}>
            <Image src={logoAdversus} />
          </Col>
          <Col width={colWidth} mb={space1}>
            <Image src={logoRateSetter} />
          </Col>
          <Col width={colWidth} mb={space1}>
            <Image src={logoCC} />
          </Col>
          <Col width={colWidth} mb={space1}>
            <Image src={logoIndustry} />
          </Col>
          <Col width={colWidth} mb={space1}>
            <Image src={logoPadsplit} />
          </Col>
        </Row>
        <Row>
          <Col width={1}>
            <Text size="xl" mb={typoSpace2} color="gray.800" as="h2" fontWeight={400}>
              Partners backed by:
            </Text>
          </Col>
        </Row>
      </Grid>
      <Grid maxWidth={1000} textAlign="center">
        <Row
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          gap={isCase ? 75 : ['l', null, 75]}
        >
          <Col width={colWidth} mb={space2}>
            <Image src={logoCombinator} />
          </Col>
          <Col width={colWidth} mb={space2}>
            <Image src={logoNorwest} />
          </Col>
          <Col width={colWidth} mb={space2}>
            <Image src={logoTribe} maxWidth={127} width="100%" />
          </Col>
          <Col width={colWidth} mb={space2}>
            <Image src={logoCIN} maxWidth={79} width="100%" />
          </Col>
          <Col width={colWidth} mb={space2}>
            <Image src={logoEmergeEducation} maxWidth={145} width="100%" />
          </Col>
          <Col width={colWidth} mb={space2}>
            <Image src={logoCapital} />
          </Col>
        </Row>
      </Grid>
    </Box>
  );
};

TrustedBy.propTypes = {
  isCase: f.bool,
};

TrustedBy.defaultProps = {
  isCase: false,
};

export default TrustedBy;
