import React from 'react';
import styled from 'styled-components';
import f from 'prop-types';

import { Container as Grid, Col, Box, Image, Title, Text, List } from '../../atoms';
import Achievements from '../Achievements';
import { styledSpaces, themeGet } from '../../../utils';

import hbTeam from './hb-team.jpg';

const StyledGrid = styled(Grid)`
  @media (min-width: ${themeGet('sizes.m')}px) {
    & {
      box-sizing: border-box;
    }
  }
`;

const WhoWeAre = ({ isCase, ...props }) => {
  return (
    <StyledGrid
      withRow
      width={isCase ? '100%' : [null, null, null, '100%']}
      maxWidth={1920}
      row={{ gap: 60, alignItems: 'center' }}
      pl={isCase ? 0 : ['m', 'l', null, 0]}
      {...styledSpaces(props)}
    >
      <Col width={isCase ? 0.5 : [1, null, null, 1 / 2]} mb={isCase ? 0 : ['l', 'xl', 'xxl', 0]}>
        <Box maxWidth={828}>
          <Image src={hbTeam} />
        </Box>
      </Col>
      <Col width={isCase ? 0.5 : [1, null, null, 1 / 2]}>
        <Box maxWidth={isCase ? 600 : [null, null, null, 600]}>
          <Title size="h2" mb={isCase ? 'xxl' : ['m', 'l', 'xxl']}>
            Small = effective
          </Title>
          <Text size="l" mb="xl">
            We are surrounded by amazing cultural and physical weather climates. Our HQ is located
            in Odesa — a beautiful seaside resort near the Black Sea. Anton Checkhov lived in this
            same neighborhood, and we are just one minute away from the opera house where
            Tchaikovsky conducted.
          </Text>
          <Text as="div" mb="xxl">
            <List>
              <li>
                Working with founders from Y-Combinator, US SaaS awards winners, guys from Forbes,
                Business Insider, Daily Beast etc.
              </li>
              <li>6 years with same leadership team structure — right people at right seats</li>
              <li>Creative Director — Awwwards International Jury</li>
              <li>Got more than 100000+ followers across all design social networks</li>
              <li>Companies we’ve worked with got more than $200M+ in funding</li>
              <li>Spent $200,000 building our in-house web-app</li>
            </List>
          </Text>
          <Achievements />
        </Box>
      </Col>
    </StyledGrid>
  );
};

WhoWeAre.propTypes = {
  isCase: f.bool,
};

WhoWeAre.defaultProps = {
  isCase: false,
};

export default WhoWeAre;
