import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/helpers/SEO';
import Base from './Base';
import { Container, Title, Flex, Divider, Box } from '../components/atoms';
import Author from '../components/molecules/Author';
// import Share from '../components/molecules/Share';
import { postDescription, format } from '../components/helpers/contentful';
import WrittenBy from '../components/molecules/WrittenBy';
import ProofSection from '../components/molecules/ProofSection';

const BlogPost = ({ data: { contentfulBlogPost: post } }) => {
  const { title, publishDate, shortDescription, body, author, slug } = post;
  // const url = `${process.env.GATSBY_BASE_URL}/${slug}`;
  // const shareDescription = postDescription(shortDescription.json);

  return (
    <Base>
      <SEO title={title} description={postDescription(shortDescription.json)} slug={`/${slug}`} />
      <Box width="100%" overflow="hidden">
        <Container maxWidth={700} mt={['l', 56, 76]} mb={[64, 112, null, 144]}>
          <Title as="h1" size="h2" mb={['m', 'xl']}>
            {title}
          </Title>
          <Flex justifyContent="space-between" alignItems="center" mb={['xxl', 64]}>
            <Author date={publishDate} author={author} />
          </Flex>
          <Divider mb={['xxl', 64]} />
          <Box mb={['xxl', 64]} style={{ wordBreak: 'break-word' }}>
            {format(body.json)}
          </Box>
          <Divider mb={['xxl', 64]} />
          <WrittenBy author={author} />
        </Container>
        <Divider mb={[56, 100, 130]} />
        <ProofSection />
      </Box>
    </Base>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPost: PropTypes.shape(),
  }),
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate
      slug
      author {
        name
        caption
        avatar {
          id
          file {
            url
          }
        }
      }
      shortDescription {
        json
      }
      body {
        json
      }
    }
  }
`;
