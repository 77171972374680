import React from 'react';
import f from 'prop-types';

import { Box } from '../../atoms';
import TrustedBy from '../TrustedBy';
import WhoWeAre from '../WhoWeAre';

const ProofSection = ({ isCase }) => {
  const space = 180;
  const space2 = isCase ? 100 : ['xxl', 64, 100, null];
  const sectionSpace = isCase ? 100 : [...space2, space];
  const sectionSpace2 = isCase ? 120 : [...space2, space - 60];

  return (
    <Box mb={sectionSpace}>
      <TrustedBy mb={sectionSpace2} isCase={isCase} />
      <WhoWeAre isCase={isCase} />
    </Box>
  );
};

ProofSection.propTypes = {
  isCase: f.bool,
};

ProofSection.defaultProps = {
  isCase: false,
};

export default ProofSection;
