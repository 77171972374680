import React from 'react';
import PropTypes from 'prop-types';

import { Box, Image, Text } from '../atoms';
import avatarHB from '../../assets/img/avatar-hb.png';

const WrittenBy = ({ author }) => {
  const name = (author && author.name) || 'Heartbeat agency';
  const caption = (author && author.caption) || 'We literally work in unison with your heart rate';
  const avatar = author && author.avatar && author.avatar.file.url;

  return (
    <Box
      display={['block', 'flex']}
      flex={1}
      mr={[null, 'm']}
      mb={[40, 0]}
      textAlign={['center', 'left']}
    >
      <Box mr={['auto', 'm']} ml={['auto', 0]} width={84} mb={['s', 0]} style={{ flexShrink: 0 }}>
        {avatar ? (
          <Image src={avatar} fluid borderRadius="50%" />
        ) : (
          <Image src={avatarHB} fluid borderRadius="50%" border="1px solid #eaeaea" />
        )}
      </Box>
      <Box>
        <Text
          color="grayscale.800"
          mb={0}
          fontSize={14}
          style={{ textTransform: 'uppercase' }}
          as="div"
        >
          Written by
        </Text>
        <Text fontSize={26} mb={4} as="div">
          <strong style={{ fontWeight: 500 }}>{name}</strong>
        </Text>
        <Text color="grayscale.800" mb={0} as="div" fontSize={['m', 'l']}>
          {caption}
        </Text>
      </Box>
    </Box>
  );
};

WrittenBy.propTypes = {
  author: PropTypes.shape(),
};

export default WrittenBy;
